// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/link-arrow.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.link-list li {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 7px auto;
  background-position: center left;
  margin-bottom: 1rem;
}

.link-list li a {
  color: var(--orange) !important;
  margin-left: 1rem;
  text-decoration: none;
}

.link-list li a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkList.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,yDAAsD;EACtD,4BAA4B;EAC5B,yBAAyB;EACzB,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".link-list {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n}\n\n.link-list li {\n  background-image: url(../assets/images/link-arrow.png);\n  background-repeat: no-repeat;\n  background-size: 7px auto;\n  background-position: center left;\n  margin-bottom: 1rem;\n}\n\n.link-list li a {\n  color: var(--orange) !important;\n  margin-left: 1rem;\n  text-decoration: none;\n}\n\n.link-list li a:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
