// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --orange: #fd4703;
  --black: #000000;
  --gray: #7f7f7f;
  --gray-dark: #2f2f2f;

  --max-width: 1075px;

  --margin: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/variables.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,oBAAoB;;EAEpB,mBAAmB;;EAEnB,cAAc;AAChB","sourcesContent":[":root {\n  --orange: #fd4703;\n  --black: #000000;\n  --gray: #7f7f7f;\n  --gray-dark: #2f2f2f;\n\n  --max-width: 1075px;\n\n  --margin: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
