// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#info-box {
  max-width: var(--max-width);
  background: var(--gray-dark);
  color: white;

  padding: 4rem 12rem;

  margin: auto;
  margin-top: -2rem;
  box-sizing: border-box;

  z-index: 2;
  position: relative;
}

/* MOBILE */

@media (max-width: 768px) {
  #info-box {
    padding: 4rem 6rem;
  }
}

@media (max-width: 576px) {
  #info-box {
    padding: 4rem 3rem;
  }

  #info-box h1 {
    font-size: 24px;
  }

  #info-box p {
    font-size: 18px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/InfoBox.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,YAAY;;EAEZ,mBAAmB;;EAEnB,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;;EAEtB,UAAU;EACV,kBAAkB;AACpB;;AAEA,WAAW;;AAEX;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":["#info-box {\n  max-width: var(--max-width);\n  background: var(--gray-dark);\n  color: white;\n\n  padding: 4rem 12rem;\n\n  margin: auto;\n  margin-top: -2rem;\n  box-sizing: border-box;\n\n  z-index: 2;\n  position: relative;\n}\n\n/* MOBILE */\n\n@media (max-width: 768px) {\n  #info-box {\n    padding: 4rem 6rem;\n  }\n}\n\n@media (max-width: 576px) {\n  #info-box {\n    padding: 4rem 3rem;\n  }\n\n  #info-box h1 {\n    font-size: 24px;\n  }\n\n  #info-box p {\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
