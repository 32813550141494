import React, { useEffect } from "react";

import "./InfoBox.css";
import Button from "./Button";

function InfoBox() {
  useEffect(() => {
    // console.log("InfoBox on renderöitynyt.");
  }, []);

  return (
    <div className="section-wrapper">
      <div id="info-box">
        <h1>TOP 10 RENGASVALMISTAJA MAAILMASSA</h1>
        <p>
          Taiwanista kotoisin oleva Maxxis International on yksi maailman
          suurimmista ja arvostetuimmista rengasvalmistajista. Maxxis toimittaa
          korkealaatuisia ja kestävällä tavalla valmistettuja renkaita mm.
          henkilö-, paketti-, maasto- ja kuorma-autoihin, offroad-ajoon,
          moottoripyöriin ja motocrossiin, mönkijöihin, perävaunuihin,
          mikroautoihin, ruohonleikkureihin sekä teollisuuskäyttöön. Maxxis on
          monelle tuttu myös laadukkaista polkupyörän renkaista, joita löytyy
          useista pyörämalleista ensiasennettuna.
        </p>
        <p>
          Maxxis International toimii tiiviissä yhteistyössä suurten
          autovalmistajien kanssa, kuten BMW ja Volkswagen. Maxxis toimittaa
          ensiasennusrenkaat mm. BMW 1-sarjaan sekä 5. sukupolven Volkswagen
          Caddyyn.
        </p>
        <p>
          Ensiasennuksen tiukat laatuvaatimukset täyttäviä renkaita on myös
          useissa muissa tuoteryhmissä, kuten ATV ja motocross. Maxxis
          International on useiden tunnettujen valmistajien yhteistyökumppani ja
          ensiasennusrengastoimittaja. Näitä valmistajia ovat: Arctic Cat, Beta,
          Can-Am, GASGAS, Honda, Kawasaki, KTM, Kymco, Polaris, Suzuki, Textron,
          TM Racing, TGB sekä Yamaha.
        </p>
        <p>
          Maxxis-strategian ytimessä on tuottaa renkaita erittäin korkeilla
          laatukriteereillä, vastuullisesti ja kohtuulliseen hintaan.
          Valitessasi Maxxis renkaat, valitset huippulaatua, johon myös tunnetut
          valmistajat luottavat.
        </p>
        <Button
          label={"Katso jälleenmyyjät"}
          type="bordered"
          textColor="orange"
          color="orange"
          href="#jalleenmyyjat"
        />
      </div>
    </div>
  );
}

export default InfoBox;
