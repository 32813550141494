import React, { useEffect } from "react";

import "./RetailersSection.css";
import useSmoothScrollTo from "SmoothScroll";
import MapContainer from "./MapContainer";

function RetailersSection() {
  useEffect(() => {
    // console.log("RetailersSection rendered", retailers);
  }, []);

  const bind = useSmoothScrollTo("#jalleenmyyjat");

  return (
    <div {...bind} className="jalleenmyyjat">
      <h2>Löydä lähin jälleenmyyjäsi</h2>
      <div className="section-wrapper">
        <div id="map-wrapper">
          <MapContainer />
        </div>
      </div>
    </div>
  );
}

export default RetailersSection;
