import React, { useEffect } from "react";

import "./Footer.css";
import LinkList from "./LinkList";

function Footer({}) {
  const logo = require("../assets/images/vapa-logo.png");

  useEffect(() => {
    // console.log("Footer rendered");
  }, []);

  return (
    <div id="footer">
      <h2>MAXXIS-RENKAIDEN MAAHANTUONTI JA TUKKUMYYNTI</h2>

      <div id="footer-content">
        <div>
          <p>
            <img src={logo} alt="VA-PA Tuote Oy" />
          </p>

          <p>
            VA-PA Tuote Oy
            <br />
            Sievarinkatu 4<br />
            FI-29200 Harjavalta
            <br />
            FINLAND
            <br />
            tel. <a href="tel:+35826741650">+358 (0)2 674 1650</a>
            <br />
            <a href="mailto:vapa@vapa.fi">vapa@vapa.fi</a>
          </p>
        </div>

        <div>
          <p>Tukkureiden verkkokauppa:</p>
          <LinkList
            links={[
              { href: "https://vapa.fi/", target: "_blank", label: "vapa.fi" },
            ]}
          />
        </div>

        <div>
          <p>Maxxis lisätietoa:</p>
          <LinkList
            links={[
              {
                href: "https://www.maxxis.com/uk/",
                target: "_blank",
                label: "maxxis.com",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
