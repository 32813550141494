import React, { useEffect } from "react";

import "./Button.css";

function Button({ label, href, textColor, type, color }) {
  useEffect(() => {
    // console.log("Carousel rendered");
  }, []);

  let classes = "button ";
  classes += type === "bordered" ? "bordered " : "filled ";
  classes += color === "orange" ? "orange " : "black ";
  classes += textColor === "white" ? "white" : "black";

  return (
    <a href={href} className={classes}>
      {label}
    </a>
  );
}

export default Button;
