import { useEffect, useRef } from "react";

const useSmoothScrollTo = (id) => {
  const ref = useRef(null);
  const screenWidth = window.innerWidth;
  const isMobileDevice = screenWidth <= 768;
  const yOffset = isMobileDevice ? 0 : -90;

  useEffect(() => {
    const hash = window.location.hash;
    if (hash && ref.current && window.location.hash === id) {
      setTimeout(() => {
        const yOffsetAdjusted =
          ref.current.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: yOffsetAdjusted, behavior: "smooth" });
      }, 750);
    }

    const listener = (e) => {
      if (ref.current && window.location.hash === id) {
        const yOffsetAdjusted =
          ref.current.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: yOffsetAdjusted, behavior: "smooth" });

        // Remove hash from url
        setTimeout(() => {
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
        }, 0);
      }
    };
    window.addEventListener("hashchange", listener, true);
    return () => {
      window.removeEventListener("hashchange", listener);
    };
  }, []);
  return {
    "data-anchor-id": id,
    ref,
  };
};

export default useSmoothScrollTo;
