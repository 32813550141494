// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.renkaat {
  margin-top: 6rem;
  text-align: center;
}

.renkaat .section-wrapper {
  text-align: center;
}

.renkaat .cards-wrapper {
  max-width: var(--max-width);
  margin: 2rem auto 0;
}

@media (max-width: 576px) {
  .cards-wrapper > div {
    height: auto !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Tyres.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".renkaat {\n  margin-top: 6rem;\n  text-align: center;\n}\n\n.renkaat .section-wrapper {\n  text-align: center;\n}\n\n.renkaat .cards-wrapper {\n  max-width: var(--max-width);\n  margin: 2rem auto 0;\n}\n\n@media (max-width: 576px) {\n  .cards-wrapper > div {\n    height: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
