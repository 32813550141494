import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setVehicleType } from "../store";

import "./VehicleTypeSelection.css";
import ToggleButton from "./ToggleButton";

function VehicleTypeSelection({}) {
  const { vehicleTypes } = useSelector((state) => state.appState);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("VehicleTypeSelection rendered", vehicleTypes);
  }, []);

  return (
    <ul id="vehicle-type-selection">
      {vehicleTypes.map((item, index) => (
        <li key={index} data-key={index}>
          <ToggleButton
            selected={vehicleTypes[index].isChecked}
            label={item.label}
            color={"orange"}
            onSelect={() => dispatch(setVehicleType(item))}
          />
        </li>
      ))}
    </ul>
  );
}

export default VehicleTypeSelection;
