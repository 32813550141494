// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer {
  background: var(--black);
  overflow: auto;
  padding: 6rem 0;
}

#footer h2 {
  color: white;
  text-align: center;
  font-size: 30px;
}

#footer-content {
  margin: 3rem auto 0;
  color: white;
  display: flex;
  width: 100%;
  max-width: 900px;
  justify-content: space-between;
  text-align: left;
}

#footer-content div:first-of-type {
  display: flex;
  gap: 2rem;
}

#footer-content p {
  padding: 0;
  margin: 0 0 0.5rem 0;
}

#footer img {
  width: 66px;
}

#footer a {
  color: white;
  text-decoration: none;
}

#footer a:hover {
  text-decoration: underline;
}

/* MOBILE */

@media (max-width: 768px) {
  #footer {
    margin-top: -1px;
    padding: 0rem 0 6rem;
    text-align: center;
  }

  #footer-content {
    display: inline-flex;
    flex-direction: column;
    align-items: start;
    width: auto;
    margin: 1rem auto 0;
  }

  #footer-content div:first-of-type {
    flex-direction: column;
  }

  #footer-content div:not(:first-of-type) {
    margin-top: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA,WAAW;;AAEX;EACE;IACE,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;IACpB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":["#footer {\n  background: var(--black);\n  overflow: auto;\n  padding: 6rem 0;\n}\n\n#footer h2 {\n  color: white;\n  text-align: center;\n  font-size: 30px;\n}\n\n#footer-content {\n  margin: 3rem auto 0;\n  color: white;\n  display: flex;\n  width: 100%;\n  max-width: 900px;\n  justify-content: space-between;\n  text-align: left;\n}\n\n#footer-content div:first-of-type {\n  display: flex;\n  gap: 2rem;\n}\n\n#footer-content p {\n  padding: 0;\n  margin: 0 0 0.5rem 0;\n}\n\n#footer img {\n  width: 66px;\n}\n\n#footer a {\n  color: white;\n  text-decoration: none;\n}\n\n#footer a:hover {\n  text-decoration: underline;\n}\n\n/* MOBILE */\n\n@media (max-width: 768px) {\n  #footer {\n    margin-top: -1px;\n    padding: 0rem 0 6rem;\n    text-align: center;\n  }\n\n  #footer-content {\n    display: inline-flex;\n    flex-direction: column;\n    align-items: start;\n    width: auto;\n    margin: 1rem auto 0;\n  }\n\n  #footer-content div:first-of-type {\n    flex-direction: column;\n  }\n\n  #footer-content div:not(:first-of-type) {\n    margin-top: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
