// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  border-width: 3px;
  border-style: solid;
  display: inline-flex;
  height: 45px;
  align-items: center;
  padding: 0 2rem;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  font-weight: 500;
}

/* BORDERED */

.button.bordered {
  background: none;
}

.button.bordered.black {
  border-color: var(--black);
}

.button.bordered.black:hover {
  background: var(--black);
}

.button.bordered.orange {
  border-color: var(--orange);
}

.button.bordered.orange:hover {
  background: var(--orange);
}

/* FILLED */

.button.filled {
}

.button.filled.black {
  border-color: var(--black);
  background: var(--black);
}

.button.filled.orange {
  border-color: var(--orange);
  background: var(--orange);
}

/* MOBILE */

@media (max-width: 576px) {
  .button {
    height: 43px;
    padding: 0 1.5rem;
    font-weight: 500;
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA,aAAa;;AAEb;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,WAAW;;AAEX;AACA;;AAEA;EACE,0BAA0B;EAC1B,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA,WAAW;;AAEX;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":[".button {\n  border-width: 3px;\n  border-style: solid;\n  display: inline-flex;\n  height: 45px;\n  align-items: center;\n  padding: 0 2rem;\n  text-transform: uppercase;\n  color: white;\n  text-decoration: none;\n  font-weight: 500;\n}\n\n/* BORDERED */\n\n.button.bordered {\n  background: none;\n}\n\n.button.bordered.black {\n  border-color: var(--black);\n}\n\n.button.bordered.black:hover {\n  background: var(--black);\n}\n\n.button.bordered.orange {\n  border-color: var(--orange);\n}\n\n.button.bordered.orange:hover {\n  background: var(--orange);\n}\n\n/* FILLED */\n\n.button.filled {\n}\n\n.button.filled.black {\n  border-color: var(--black);\n  background: var(--black);\n}\n\n.button.filled.orange {\n  border-color: var(--orange);\n  background: var(--orange);\n}\n\n/* MOBILE */\n\n@media (max-width: 576px) {\n  .button {\n    height: 43px;\n    padding: 0 1.5rem;\n    font-weight: 500;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
