import React, { useEffect } from "react";

import "./ToggleButton.css";

function ToggleButton({ label, selected, color, onSelect }) {
  useEffect(() => {
    // console.log("ToggleButton rendered");
  }, []);

  const onClick = (e) => {
    if (onSelect) onSelect();
  };

  let classes = "toggle-button ";
  classes += selected ? "selected " : "";
  classes += color === "orange" ? "orange " : "black ";

  return (
    <button className={classes} onClick={onClick}>
      {label}
    </button>
  );
}

export default ToggleButton;
