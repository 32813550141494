import React, { useEffect } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";

import "./Header.css";

function Header() {
  useEffect(() => {
    // console.log("Header rendered");
  }, []);

  return (
    <div className="header">
      <a id="header-logo" href="#maxxis">
        <Logo />
      </a>

      <ul id="header-menu">
        <li>
          <a href="#renkaat">Renkaat</a>
        </li>
        <li>
          <a href="#jalleenmyyjat">Jälleenmyyjät</a>
        </li>
      </ul>
    </div>
  );
}

export default Header;
