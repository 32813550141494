// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: var(--margin);
  padding-right: var(--margin);

  height: 90px;
}

@media (min-width: 768px) {
  .header {
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
  }

  body {
    padding-top: 90px;
  }
}

/* Header logo */

#header-logo {
  height: 40px;
  width: auto;
}

#header-logo svg {
  height: 40px;
  width: auto;
}

/* Header menu */

#header-menu {
  display: flex;
}

#header-menu li {
  list-style: none;
  margin-left: var(--margin);
}

#header-menu li a {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  text-decoration: none;
  font-weight: 500;
}

/* MOBILE */

@media (max-width: 768px) {
  #header-logo {
    margin: auto;
  }

  #header-menu {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;;EAE9B,2BAA2B;EAC3B,4BAA4B;;EAE5B,YAAY;AACd;;AAEA;EACE;IACE,eAAe;IACf,aAAa;IACb,WAAW;IACX,MAAM;IACN,OAAO;IACP,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA,gBAAgB;;AAEhB;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA,gBAAgB;;AAEhB;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA,WAAW;;AAEX;EACE;IACE,YAAY;EACd;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".header {\n  background: black;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  padding-left: var(--margin);\n  padding-right: var(--margin);\n\n  height: 90px;\n}\n\n@media (min-width: 768px) {\n  .header {\n    position: fixed;\n    z-index: 1000;\n    width: 100%;\n    top: 0;\n    left: 0;\n    box-sizing: border-box;\n  }\n\n  body {\n    padding-top: 90px;\n  }\n}\n\n/* Header logo */\n\n#header-logo {\n  height: 40px;\n  width: auto;\n}\n\n#header-logo svg {\n  height: 40px;\n  width: auto;\n}\n\n/* Header menu */\n\n#header-menu {\n  display: flex;\n}\n\n#header-menu li {\n  list-style: none;\n  margin-left: var(--margin);\n}\n\n#header-menu li a {\n  color: white;\n  text-transform: uppercase;\n  font-size: 20px;\n  text-decoration: none;\n  font-weight: 500;\n}\n\n/* MOBILE */\n\n@media (max-width: 768px) {\n  #header-logo {\n    margin: auto;\n  }\n\n  #header-menu {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
