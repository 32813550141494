import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import Isotope from "isotope-layout";

import "./Tyres.css";
import VehicleTypeSelection from "./VehicleTypeSelection";
import TyresSelection from "./TyresSelection";
import useSmoothScrollTo from "SmoothScroll";
import Card from "./Card";

function Tyres({}) {
  const containerRef = useRef(null);
  const isoRef = useRef(null);

  const { activeFilters, tyresData } = useSelector((state) => state.appState);

  useEffect(() => {
    // Check if the screen width is greater than a certain threshold (e.g., 768px for tablets).
    // const isMobile = window.innerWidth <= 576;

    // if (!isMobile)
    isoRef.current = new Isotope(containerRef.current, {
      itemSelector: ".card",
      stagger: 0,
      transitionDuration: 500,
      masonry: {
        columnWidth: ".grid-sizer",
        gutter: ".gutter-sizer",
      },
    });
  }, []);

  useEffect(() => {
    const filters = activeFilters
      .filter((filter) => filter.isChecked)
      .map((filter) => `.${filter.label}`)
      .join("");

    isoRef.current.arrange({ filter: filters });
  }, [activeFilters]);

  const bind = useSmoothScrollTo("#renkaat");

  return (
    <div {...bind} className="renkaat">
      <div className="section-wrapper">
        <h2>Renkaat</h2>

        <VehicleTypeSelection />
        <TyresSelection />

        <div className="cards-wrapper">
          <div ref={containerRef}>
            <div className="grid-sizer"></div>
            <div className="gutter-sizer"></div>
            {tyresData.map((card, index) => (
              <Card key={index} data={card} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tyres;
