// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/fonts/Rubik-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../src/assets/fonts/Rubik-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Rubik;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: Rubik;
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

* {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

body {
  margin: 0;
  background: #f2f2f2;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
  text-transform: uppercase;
  line-height: 120%;
}

.section-wrapper {
  padding: 0 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,4CAA+C;AACjD;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,4CAAgD;AAClD;;AAEA;EACE;;yBAEuB;AACzB;;AAEA;EACE,SAAS;EACT,mBAAmB;EACnB;;cAEY;EACZ,gBAAgB;EAChB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;;;;EAIE,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["@font-face {\n  font-family: Rubik;\n  font-weight: 400;\n  src: url(../src/assets/fonts/Rubik-Regular.ttf);\n}\n\n@font-face {\n  font-family: Rubik;\n  font-weight: 500;\n  src: url(../src/assets/fonts/Rubik-SemiBold.ttf);\n}\n\n* {\n  font-family: Rubik, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\",\n    \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif !important;\n}\n\nbody {\n  margin: 0;\n  background: #f2f2f2;\n  font-family: Rubik, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\",\n    \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  font-weight: 400;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\nh1,\nh2,\nh3,\nh4 {\n  font-weight: 500;\n  text-transform: uppercase;\n  line-height: 120%;\n}\n\n.section-wrapper {\n  padding: 0 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
