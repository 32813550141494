// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map-container {
}

.gm-style-iw button {
  top: 0px !important;
  right: 5px !important;
}

.gm-style-iw button span {
  width: 22px !important;
  height: 22px !important;
}

.info-window {
  text-align: left;
  padding: 1rem;
  width: 200px;
}

.info-window h3 {
  margin: 0;
  padding: 0;
}

.info-window p {
  margin: 0;
  padding: 0 0 1rem 0;
}

.info-window .link-list li {
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/MapContainer.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":["#map-container {\n}\n\n.gm-style-iw button {\n  top: 0px !important;\n  right: 5px !important;\n}\n\n.gm-style-iw button span {\n  width: 22px !important;\n  height: 22px !important;\n}\n\n.info-window {\n  text-align: left;\n  padding: 1rem;\n  width: 200px;\n}\n\n.info-window h3 {\n  margin: 0;\n  padding: 0;\n}\n\n.info-window p {\n  margin: 0;\n  padding: 0 0 1rem 0;\n}\n\n.info-window .link-list li {\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
