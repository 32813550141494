import { configureStore, createSlice } from "@reduxjs/toolkit";

const retailers = require("./retailers.json");
const tyres = require("./tyres.json");

const initialState = {
  retailers: retailers,
  vehicleTypes: [
    { label: "Henkilöauto", isChecked: true },
    { label: "Pakettiauto", isChecked: false },
    { label: "Erikoisrenkaat", isChecked: false },
  ],
  normalTyres: [
    { label: "Kesärenkaat", isChecked: true },
    { label: "Talvirenkaat", isChecked: false },
  ],
  specialTyres: [
    { label: "Maastoauto", isChecked: false },
    { label: "Moottoripyörä", isChecked: false },
    { label: "Offroad", isChecked: false },
    { label: "Karting", isChecked: false },
    { label: "ATV", isChecked: false },
    { label: "Motocross", isChecked: false },
    { label: "Valkosivu", isChecked: false },
  ],
  activeFilters: [],
  tyresData: tyres,
  cardZIndex: 0,
};

const updateFilters = (state) => {
  const filters = [];

  filters.push(...state.vehicleTypes);
  filters.push(...state.normalTyres);
  filters.push(...state.specialTyres);

  state.activeFilters = filters;
};

const resetNormalTyres = (state, activeIndex) => {
  state.normalTyres = state.normalTyres.map((tyre, index) => {
    tyre.isChecked = index === activeIndex;
    return tyre;
  });
};

const resetSpecialTyres = (state, activeIndex) => {
  state.specialTyres = state.specialTyres.map((tyre, index) => {
    tyre.isChecked = index === activeIndex;
    return tyre;
  });
};

const appState = createSlice({
  name: "appState",
  initialState: initialState,
  reducers: {
    setVehicleType: (state, action) => {
      // Switch TO special tyres
      if (
        action.payload.label === state.vehicleTypes[2].label &&
        !state.vehicleTypes[2].isChecked
      ) {
        resetNormalTyres(state, -1);
        resetSpecialTyres(state, 0);
      } else if (
        action.payload.label !== state.vehicleTypes[2].label &&
        state.vehicleTypes[2].isChecked
      ) {
        resetNormalTyres(state, 0);
        resetSpecialTyres(state, -1);
      }

      // Update vehicle types
      state.vehicleTypes = state.vehicleTypes.map((type, index) => {
        type.isChecked = action.payload.label === type.label;
        return type;
      });

      updateFilters(state);
    },

    setTyreType: (state, action) => {
      if (state.vehicleTypes[2].isChecked) {
        // Update normal types
        state.normalTyres = state.normalTyres.map((tyre, index) => {
          tyre.isChecked = false;
          return tyre;
        });

        // Update special types
        state.specialTyres = state.specialTyres.map((tyre, index) => {
          tyre.isChecked = action.payload.label === tyre.label;
          return tyre;
        });
      } else {
        // Update normal types
        state.normalTyres = state.normalTyres.map((tyre, index) => {
          tyre.isChecked = action.payload.label === tyre.label;
          return tyre;
        });

        // Update special types
        state.specialTyres = state.specialTyres.map((tyre, index) => {
          tyre.isChecked = false;
          return tyre;
        });
      }

      updateFilters(state);
    },

    incrementZIndex: (state, action) => {
      state.cardZIndex += 1;
    },

    updateActiveFilters: (state) => {
      updateFilters(state);
    },
  },
});

// Create Redux-store and add slices
const store = configureStore({
  reducer: {
    appState: appState.reducer,
  },
});

// Export reducers for components
export const {
  setVehicleType,
  setTyreType,
  incrementZIndex,
  updateActiveFilters,
} = appState.actions;

export default store;
