import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

import "./MapContainer.css";
import LinkList from "./LinkList";
import { mapDefaultOptions } from "./MapOptions";

function MapContainer({ google }) {
  const { retailers } = useSelector((state) => state.appState);
  const [markersState, setMarkerState] = useState({
    showingInfoWindow: false,
    activeMarker: null,
    retailer: null,
  });

  useEffect(() => {
    // console.log("MapContainer rendered", retailers);
  }, []);

  const initialCenter = {
    lat: 62.5846828,
    lng: 25.542225,
  };

  if (isMobile) {
    mapDefaultOptions.minZoom = 5.6;
    initialCenter.lat += 1;
    initialCenter.lng += 0.5;
  }

  const onMapReady = (mapProps, map) => {
    map.setOptions(mapDefaultOptions);
  };

  const onMarkerClick = (props, marker, e) => {
    setMarkerState({
      activeMarker: marker,
      retailer: marker.retailer,
      showingInfoWindow: true,
    });
  };

  const onMapClicked = () => {
    if (markersState.showingInfoWindow)
      setMarkerState({
        activeMarker: null,
        retailer: null,
        showingInfoWindow: false,
      });
  };

  return (
    <Map
      google={google}
      initialCenter={initialCenter}
      style={{ width: "100%", height: "100%" }}
      containerStyle={{ width: "100%", height: "100%" }}
      zoom={mapDefaultOptions.minZoom}
      onReady={onMapReady}
      onClick={onMapClicked}
    >
      {retailers.map((retailer, index) => (
        <Marker
          key={"id" + index}
          retailer={retailer}
          position={retailer.position}
          onClick={onMarkerClick}
          icon={{
            url: "/assets/images/marker.png",
            anchor: new google.maps.Point(20, 40),
            scaledSize: new google.maps.Size(40, 40),
          }}
        />
      ))}
      <InfoWindow
        marker={markersState.activeMarker}
        onClose={onMapClicked}
        visible={markersState.showingInfoWindow}
      >
        {!!markersState.retailer && (
          <div className="info-window">
            <h3>{markersState.retailer.name}</h3>
            <p>
              {markersState.retailer.address}
              <br />
              {markersState.retailer.zip} {markersState.retailer.city}
              <br />
            </p>
            {!!markersState.retailer.website && (
              <LinkList
                links={[
                  {
                    href: markersState.retailer.website,
                    target: "_blank",
                    label: "Sivusto",
                  },
                ]}
              />
            )}
          </div>
        )}
      </InfoWindow>
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyD8PmojzbOCq4Xe9u1kjkFYxzLzK9OpfBE",
})(MapContainer);
