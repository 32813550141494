import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setTyreType } from "../store";

import "./TyresSelection.css";
import ToggleButton from "./ToggleButton";

function TyresSelection({}) {
  const { normalTyres, specialTyres, vehicleTypes } = useSelector(
    (state) => state.appState
  );
  const dispatch = useDispatch();

  const [tyres, setTyres] = useState(normalTyres);

  useEffect(() => {
    // console.log("TyresSelection rendered", specialTyres);
  }, []);

  useEffect(() => {
    setTyres(vehicleTypes[2].isChecked ? specialTyres : normalTyres);
  }, [normalTyres, specialTyres]);

  return (
    <ul id="tyres-selection">
      {tyres.map((item, index) => (
        <li key={index} data-key={index}>
          <ToggleButton
            selected={item.isChecked}
            label={item.label}
            color={"black"}
            onSelect={() => dispatch(setTyreType(item))}
          />
        </li>
      ))}
    </ul>
  );
}

export default TyresSelection;
