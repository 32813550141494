import React, { useEffect } from "react";

import "./LinkList.css";

function LinkList({ links }) {
  useEffect(() => {
    // console.log("LinkList rendered");
  }, []);

  return (
    <ul className="link-list">
      {links.map((link, index) => (
        <li key={index}>
          <a href={link.href} target={link.target || "_self"}>
            {link.label}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default LinkList;
