// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-button {
  border-width: 3px;
  border-style: solid;
  border-color: var(--black);

  display: inline-flex;
  height: 45px;
  align-items: center;
  padding: 0 1.5rem;
  text-transform: uppercase;
  color: var(--black);
  text-decoration: none;
  background: none;

  cursor: pointer;

  font-weight: 500;
}

/* SELECTED STATE */

.toggle-button.selected {
  color: white;
}

.toggle-button.selected.black {
  border-color: var(--black);
  background: var(--black);
}

.toggle-button.selected.orange {
  border-color: var(--orange);
  background: var(--orange);
}

/* MOBILE */

@media (max-width: 576px) {
  .toggle-button {
    font-size: 14px;
    height: 44px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ToggleButton.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,0BAA0B;;EAE1B,oBAAoB;EACpB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;;EAEhB,eAAe;;EAEf,gBAAgB;AAClB;;AAEA,mBAAmB;;AAEnB;EACE,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA,WAAW;;AAEX;EACE;IACE,eAAe;IACf,YAAY;EACd;AACF","sourcesContent":[".toggle-button {\n  border-width: 3px;\n  border-style: solid;\n  border-color: var(--black);\n\n  display: inline-flex;\n  height: 45px;\n  align-items: center;\n  padding: 0 1.5rem;\n  text-transform: uppercase;\n  color: var(--black);\n  text-decoration: none;\n  background: none;\n\n  cursor: pointer;\n\n  font-weight: 500;\n}\n\n/* SELECTED STATE */\n\n.toggle-button.selected {\n  color: white;\n}\n\n.toggle-button.selected.black {\n  border-color: var(--black);\n  background: var(--black);\n}\n\n.toggle-button.selected.orange {\n  border-color: var(--orange);\n  background: var(--orange);\n}\n\n/* MOBILE */\n\n@media (max-width: 576px) {\n  .toggle-button {\n    font-size: 14px;\n    height: 44px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
