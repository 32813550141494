// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#vehicle-type-selection {
  display: flex;
  list-style: none;
  margin: auto;
  gap: 1rem;
  justify-content: center;
  padding: 0;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/VehicleTypeSelection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,SAAS;EACT,uBAAuB;EACvB,UAAU;EACV,eAAe;AACjB","sourcesContent":["#vehicle-type-selection {\n  display: flex;\n  list-style: none;\n  margin: auto;\n  gap: 1rem;\n  justify-content: center;\n  padding: 0;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
