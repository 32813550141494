import React, { useEffect } from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Carousel.css";

function Carousel() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 200,
    fade: true,
    arrows: false,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const image1 = require("../assets/images/BMW-HP5.jpg");
  const image2 = require("../assets/images/VS-EV-Banner.jpg");
  const image3 = require("../assets/images/Maxxis-Extreme-MT.jpg");

  useEffect(() => {
    // console.log("Carousel rendered");
  }, []);

  return (
    <div id="carousel">
      <Slider {...settings}>
        <div className="content-wrapper">
          <div className="text-wrapper">
            <h3>
              Maxxis tiivistää jo ennestään vahvoja suhteita BMW-konserniin
            </h3>
            <p>Maxxis toimittaa nyt ensiasennusrenkaat BMW 1-sarjan autoihin</p>
          </div>
          <img src={image1} alt="" />
        </div>
        <div className="content-wrapper">
          <div className="text-wrapper">
            <h3>Victra Sport EV</h3>
            <p>Palkittu uuden aikakauden rengas sähköautoille</p>
          </div>
          <img src={image2} alt="" />
        </div>
        <div className="content-wrapper">
          <div className="text-wrapper">
            <h3>Voitosta voittoon</h3>
            <p>
              Mainetta ja kunniaa maailmalta keränneet Maxxis Offroad-renkaat
            </p>
          </div>
          <img src={image3} alt="" />
        </div>
      </Slider>
    </div>
  );
}

export default Carousel;
