import Header from "./components/Header";

import "./App.css";
import Carousel from "./components/Carousel";
import InfoBox from "./components/InfoBox";

import { Provider } from "react-redux";
import store from "./store"; // Tuodaan Redux-kauppa
import { updateActiveFilters } from "./store";
import { useDispatch } from "react-redux";
import Tyres from "./components/Tyres";
import RetailersSection from "./components/RetailersSection";
import Footer from "./components/Footer";
import { useEffect } from "react";
import useSmoothScrollTo from "SmoothScroll";

function App() {
  const bind = useSmoothScrollTo("#maxxis");

  return (
    <Provider store={store}>
      <div {...bind} className="App">
        <Init />
        <Header />
        <Carousel />
        <InfoBox />
        <Tyres />
        <RetailersSection />
        <Footer />
      </div>
    </Provider>
  );
}

function Init() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateActiveFilters());
  }, []);

  return null;
}

export default App;
