// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tyres-selection {
  display: flex;
  list-style: none;
  margin: 1rem auto 0;
  gap: 1rem;
  justify-content: center;
  padding: 0;
  flex-wrap: wrap;
  max-width: var(--max-width);
}
`, "",{"version":3,"sources":["webpack://./src/components/TyresSelection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;EACT,uBAAuB;EACvB,UAAU;EACV,eAAe;EACf,2BAA2B;AAC7B","sourcesContent":["#tyres-selection {\n  display: flex;\n  list-style: none;\n  margin: 1rem auto 0;\n  gap: 1rem;\n  justify-content: center;\n  padding: 0;\n  flex-wrap: wrap;\n  max-width: var(--max-width);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
