// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/terrain.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jalleenmyyjat {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  text-align: center;
  padding: 3rem 0 5rem;
  margin-top: 4rem;
}

.jalleenmyyjat h2 {
  color: white;
  padding: 0 2rem;
}

#map-wrapper {
  width: 100%;
  max-width: var(--max-width);
  height: 605px;
  box-sizing: border-box;
  margin: auto;
  margin-top: 2rem;
}

#map-wrapper > div {
  width: 100%;
  height: 100%;
  position: relative;
}

/* MOBILE */

@media (max-width: 768px) {
  .jalleenmyyjat .section-wrapper {
    padding: 0;
  }

  #map-wrapper {
    height: 670px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/RetailersSection.css"],"names":[],"mappings":"AAAA;EACE,yDAAmD;EACnD,4BAA4B;EAC5B,sBAAsB;EACtB,+BAA+B;EAC/B,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA,WAAW;;AAEX;EACE;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".jalleenmyyjat {\n  background-image: url(../assets/images/terrain.jpg);\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: top center;\n  text-align: center;\n  padding: 3rem 0 5rem;\n  margin-top: 4rem;\n}\n\n.jalleenmyyjat h2 {\n  color: white;\n  padding: 0 2rem;\n}\n\n#map-wrapper {\n  width: 100%;\n  max-width: var(--max-width);\n  height: 605px;\n  box-sizing: border-box;\n  margin: auto;\n  margin-top: 2rem;\n}\n\n#map-wrapper > div {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n/* MOBILE */\n\n@media (max-width: 768px) {\n  .jalleenmyyjat .section-wrapper {\n    padding: 0;\n  }\n\n  #map-wrapper {\n    height: 670px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
