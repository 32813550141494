import React, { useEffect, useRef, useState } from "react";

import "./Card.css";
import LinkList from "./LinkList";

import { incrementZIndex } from "../store";
import { useSelector, useDispatch } from "react-redux";

function Card({ data }) {
  const { cardZIndex } = useSelector((state) => state.appState);
  const [zIndex, setZIndex] = useState(0);
  const [isInteracting, setIsInteracting] = useState(false);
  const [links, setLinks] = useState([]);
  const [isHovering, setIsHovering] = useState(false);
  const [paddingBottom, setPaddingBottom] = useState(0);
  const dispatch = useDispatch();

  const timeoutIdRef = useRef(null);
  const cardRef = useRef(null);

  useEffect(() => {
    timeoutIdRef.current = setTimeout(() => {
      setIsHovering(isInteracting);
    }, 300);
  }, [zIndex]);

  useEffect(() => {
    if (cardRef.current) {
      const cardWidth = cardRef.current.offsetWidth;
      setPaddingBottom(isHovering ? cardWidth : 0);
    }
  }, [isHovering]);

  useEffect(() => {
    const cardLinks = [];

    cardLinks.push({
      href: "#jalleenmyyjat",
      target: "_self",
      label: "Jälleenmyyjät",
    });
    if (data.additionalLinkUrl)
      cardLinks.push({
        href: data.additionalLinkUrl,
        target: "_blank",
        label: "Lisätietoja",
      });

    setLinks(cardLinks);
  }, []);

  return (
    <div
      ref={cardRef}
      className={`card ${data.filters} ${isHovering ? "hover" : ""}`}
      onMouseEnter={() => {
        dispatch(incrementZIndex());
        setZIndex(cardZIndex + 1);
        setIsInteracting(true);
      }}
      onMouseLeave={() => {
        setIsInteracting(false);
        setIsHovering(false);
        clearTimeout(timeoutIdRef.current);
      }}
      style={{ zIndex: zIndex, paddingBottom: paddingBottom }}
    >
      {data.is_new_product && <span className="new">UUTUUS</span>}
      <h3>{data.label}</h3>
      <strong>({data.class})</strong>
      <div className="tags">
        {data.is_studded_tyre && <span className="black">Nastarengas</span>}
        {data.is_friction_tyre && <span className="gray">Kitkarengas</span>}

        {data.tags.map((tag, index) => (
          <span key={index}>{tag}</span>
        ))}
      </div>
      <p>{data.text}</p>

      {(data.for_demanding_snow_conditions ||
        data.for_demanding_ice_conditions) && (
        <ul className="friction-tyre-features">
          {data.for_demanding_snow_conditions && (
            <li className="snow">
              Vaativissa lumiolosuhteissa käytettävä rengas
            </li>
          )}
          {data.for_demanding_ice_conditions && (
            <li className="ice">
              Vaativissa jääolosuhteissa käytettävä rengas
            </li>
          )}
        </ul>
      )}

      {data.prizes.length > 0 && (
        <>
          <h4>Palkintoja:</h4>
          <ul className="prizes">
            {data.prizes.map((prize, index) => (
              <li key={index}>{prize}</li>
            ))}
          </ul>
        </>
      )}

      <LinkList links={links} />
      <div className="product-wrapper">
        <div className="gradient"></div>
        <div className="product-image">
          <img src={`assets/images/${data.image}`} alt="" />
        </div>
      </div>
      <div className="bottom-cover">
        <hr />
      </div>
    </div>
  );
}

export default Card;
